
 

    .mainform {
        border-radius: 30px;
        box-shadow: 30px 30px 60px 0px #0000001A;
    }

    .form_background {
        background-image: url('../images/form_background.png');
        background-repeat: no-repeat;
        background-size: cover;
    }

    .modal-content{
        align-items: center;
        background-image: url('../images/form_background.png');
        border-radius: 8%;
        background-size: cover;
        backdrop-filter: lightgrey;
    }
