/* Banner  */
.LiquidLove_logo {
    width: "60%";
    margin: "2%";
}

.Banner_tittle_card {
    font-size: 65px;
}

.join-as-donor {
    height: 60px;
    font-size: 20px;
    text-transform: uppercase;
}

.justifycenter {
    justify-content: left;
}

.center_alignment {
    text-align: left;
}

.banner_slogan_belowCarouseal {
    display: none
}

.banner_slogan_right {
    display: block;
}

.liquidlove_logo {
    width: "60%";
    margin: "2%";
}

.hand_with_blood {
    width: 100%;
    margin-top: -100px;
}

.button-nav {
width: 30%;
}

@media (min-width: 320px) and (max-width: 480px) {

    .hand_with_blood {
        width: 100px;
        height: 180px;
        left: -2px;
        margin-top: -40px;
    }

    .justifycenter {
        justify-content: center;
    }

    .Banner_tittle_card {
        padding-top: 10%;
    }

    .join-as-donor {
        font-size: 12px;
        width: 125px;
        height: auto;
        text-transform: uppercase;
        justify-content: center;

    }

    .join-as-donor-nav {
        position: absolute;
        font-size: 10px;
        top: 9%;
        left: 10%;

    }

    .center_alignment {
        text-align: center;
    }

    .banner_slogan_belowCarouseal {
        display: block;
        font-size: 16px;
    }

    .banner_slogan_right {
        display: none;
    }

    .liquidlove_logo {
        width: "10px";
        text-align: left;
    }

}


/* Works */
.works_tittle {
    font-size: 20px;
}

.works_card_text {
    font-size: 15px;
}

.Works_Card_head {
    font-size: 24px;
}

.card_style {
    border-Radius: '10px';
    width: '400px';
    height: '180px';
    flex-Shrink: 0;
    box-Shadow: '20px 20px 40px 0px rgba(255, 225, 225, 0.10)';
    backGround: 'var(--white-900, #FFF)';
}

.works_head {
    font-size: 54px;
}


.backdrop {
    background-image: url('../assets/images/bloodbackdrop.png');
    background-size: cover; /* Adjust as needed */
    width: 100%;
    height: 100%; /* Adjust as needed */
    /* Other styling properties can be added here */
  }

@media (min-width: 320px) and (max-width: 480px) {

    .Banner_tittle_card {
        font-size: 46px;
        text-align: center;
    }

    .works_card_text {
        font-size: 12px;
    }

    .Works_Card_head {
        font-size: 18px;
    }

    .works_tittle {
        font-size: 20px;
    }

    .works_card_row {
        margin: 0%;
    }

    .works_head {
        font-size: 28px;
    }


}


/* Benefits Page  */

.Benefits_tittle_card {
    font-size: 54px;
}

.Benefits_section {
    font-size: 24px;
    width: 80%;

}

.Benefits_bullets {
    font-size: 18px;
    padding: '20px',
}

.ready_to_be_a_here {
    color: '#D30000';
    font-family: 'Rubik';
    font-size: '24px';
    font-style: 'normal';
    font-weight: 400;
    line-height: 'normal';
    text-decoration-line: 'underline';
}

.Benefits_section_description {
    font-size: 24px;

}

.be_a_hero {
    font-size: 96px;
}

@media (min-width: 320px) and (max-width: 480px) {
    .Benefits_tittle_card {
        text-align: center;
        font-size: 24px;
    }

    .Benefits_section {
        font-size: 12px;
        text-align: center;
        width: 100%;
    }

    .Benefits_section_description {
        font-size: 12px;
    }


    .Benefits_bullets {
        font-size: 12px;
        padding: '25px',

    }

    .be_a_hero {
        font-size: 24px;
    }

    .ready_to_be_a_here {
        font-size: '12px';
        font-weight: bold;
    }
}

/* Patient form */

.form_header {
    color: #DE1717;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Abril Fatface;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    /* 44px */
}

@media (min-width: 320px) and (max-width: 480px) {
    .form_header {
        font-size: 32px;
    }
}

/* App.js */

.login_button {

    position: absolute;
    top: 8%;
    right: 3%;
    color: #FDC9C6;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Rubik";
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 14.833px;
    letter-spacing: 0.742px;
    text-transform: uppercase;

}

.login_button:focus{
    color: #FDC9C6;
}

@media (min-width: 320px) and (max-width: 480px) {
    .login_button {
        position: absolute;
        top: 2%;
        right: 1%;
        color: #FDC9C6;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Rubik;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 14.833px;
        /* 82.407% */
        letter-spacing: 0.742px;
        text-transform: uppercase;

    }
}

/* Donate blood */

.taglines {
    color: #DE1717;
    font-family: Rubik;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

@media (min-width: 320px) and (max-width: 480px) {
        
        .taglines {
            color: #DE1717;
            font-family: Rubik;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }