@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abril%20Fatface:wght@400&display=swap");

body {
  background: #ffebea;
  font-family: "Rubik", sans-serif;
  width: 100%;
  height: 100%;
}

.mainform {
  border-radius: 30px;
  box-shadow: 30px 30px 60px 0px #0000001a;
}

.loadDivStyle div:first-child {
  display: flex;
}

div.background {
  background-image: url("../images/form_background.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.modal-content {
  align-items: center;
  background-image: url("../images/form_background.png");
  border-radius: 8%;
  background-size: cover;
  backdrop-filter: lightgrey;
}

label {
  color: #2e2f35;
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.input-group-text {
  background-color: rgb(244, 246, 248);
}
h1 {
  color: #2e2f35;
  text-align: center;
  font-family: "Abril Fatface";
  font-size: 46px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  padding: 2%;
}

h3 {
  color: #de1717;
  text-align: center;
  /* font-feature-settings: 'clig' off, 'liga' off; */
  font-family: "Rubik";
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  padding: 2%;
}

canvas {
  max-width: 100%;
}

.btn-default {
  border-color: #de1717;
  color: var(--Primary, #de1717);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Rubik;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
  text-transform: uppercase;
}

.btn-default:focus {
  border-color: #ff0000;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
  color: #de1717;
}
.btn-default:hover {
  color: #de1717;
}
.form-control:focus {
  border-color: #ff0000;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
}
.bs-example {
  margin: 20px;
}
.icon-input-btn {
  display: inline-block;
  position: relative;
}
.icon-input-btn input[type="submit"] {
  padding-left: 2em;
}
.icon-input-btn .fa {
  display: inline-block;
  position: absolute;
  left: 0.65em;
  top: 30%;
}

/* for login model*/
i {
  margin-left: -30px;
  cursor: pointer;
}

form {
  padding: 15px;
}

/* Buttons */

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  margin-right: 5px;
}

a {
  color: #de1717;
}

.strips {
  background-image: url("../images/strips.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 1655.59px;
  height: 479.09px;
  top: 374px;
  left: -112px;
  position: absolute;
  top: 80px;
  right: 0;
}

/* Layout css*/
.slide1right {
  background-image: url("../images/blood_background.png");
  padding: 5%;
  width: 1024px;
  height: 1024px;
  align-items: flex-end;
}

.contact {
  background-image: url("../images/footer.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.slogen {
  color: #2e2f35;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Abril Fatface;
  font-size: 74px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 81.4px */
}

.form-control::placeholder {
  color: rgba(46, 47, 53, 0.23) !important;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.carouselbackground {
  background-image: url("../images/blood_background.png");
  /* align-items: flex-end; */
}

.slider-wrapper {
  /* width: 760px; */
  margin: auto;
  margin-left: -15%;
}

.slick-slide {
  text-align: center;
  position: relative;
}
.slick-slide:focus {
  outline: none;
}

.slick-slide-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  /* box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
    0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03); */
}

.slick-prev:before,
.slick-next:before {
  color: #777777;
}

.thumbnail-slider-wrap {
  margin-top: 15px;
  height: 85px;
  margin-bottom: 45px;
}
.thumbnail-slider-wrap .slick-track .slick-slide {
  text-align: center;
}
.thumbnail-slider-wrap .slick-track .slick-slide img {
  width: 65%;
}

.thumbnail-slider-wrap {
  padding-left: 25%;
  padding-top: 25px;
}

.request-blood {
font-size: 20px;
width: 30%;
height: 60px;
text-transform: uppercase;
}

/* Mobile media query */
@media (min-width: 481px) and (max-width: 767px) {
  .thumbnail-slider-wrap {
    padding-left: 0;
    padding-top: 5px;
  }

  .slider-wrapper {
    margin-left: 0;
  }
  .request-blood {
    display: none !important;
  }

  
  .slider-wrapper {
    margin-top: -100px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .thumbnail-slider-wrap {
    padding-left: 0;
    padding-top: 5px;
  }
  .slider-wrapper {
    margin-left: 0;
  }
  .request-blood {
    /* display: none !important; */
    height: auto;
    width: 130px;
    font-size: 10px;
    position: absolute;
    top: 9%;
    right: 7%;
    font-size: 10px;
  }
  .slider-wrapper {
    margin-top: -100px;
  }
}
